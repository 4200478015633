import { computeGrandTotal } from '../../common/checkout'
import { surcharge } from '../../api-common/payments'

export const surchargeAmount = (cart, payment_method) => {
  let bankWireOnlyItems = cart.products.filter(item => item.ask_for_price === 2).length > 0
  return bankWireOnlyItems && payment_method ? surcharge(payment_method, cart.total) : 0
}

export default (cart, payment_method='') => {
  let coupon =  cart.coupon_result || {
    free_shipping: false,
    discount: 0,
  }
  const processingFee = surchargeAmount(cart, payment_method)
  return computeGrandTotal(cart.total, cart.shipping, cart.tax, coupon, processingFee)
}
