import * as React from 'react'
import { useCallback } from 'react'
import CategoryImage from '../CategoryImage'
import { TailwindScreensEM } from '../../helpers/tailwind'
import PropTypes from 'prop-types'
import { IconChevronLeft, IconChevronRight } from '../Icons'
import Slider from './Slider'

const ImageGallerySliderItem = ({ children, onClick, className = 'aspect-square' }) => {
  return (
    <button onClick={onClick} className={className || 'aspect-square'}>
      {children}
    </button>
  )
}
const ImageGallerySliderButtonPrev = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={
        'absolute w-8 h-20 top-[50%] hover:-left-2 left-0 bg-gray-100 opacity-50 group-hover:opacity-100 duration-300 z-20' +
        (disabled ? 'hidden' : '')
      }
    >
      <IconChevronLeft />
    </button>
  )
}

const ImageGallerySliderButtonNext = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={
        'absolute w-8 h-20 top-[50%] hover:-right-2 right-0 bg-gray-100 opacity-50 group-hover:opacity-100 duration-300 z-20 ' +
        (disabled ? 'hidden' : '')
      }
    >
      <IconChevronRight />
    </button>
  )
}

const ImageGallerySliderItemsPerScreen = () => {
  return [
    useCallback(() => {
      return 1
    }, []),
    'basis-full',
  ]
}

const CartItemImageSlider = ({
  product,
  media_gallery,
  onClick,
  sliderElementClassName = 'aspect-square',
  categoryImageClassName = 'aspect-square w-full h-full',
}) => {
  const sizes = `${TailwindScreensEM.lg} 25em, 100vw`

  return (
    <Slider
      className={'group p-2'}
      buttonPrev={ImageGallerySliderButtonPrev}
      buttonNext={ImageGallerySliderButtonNext}
      childrenClasses="flex-shrink-0"
      itemsPerScreenFactory={ImageGallerySliderItemsPerScreen}
    >
      {media_gallery?.map((slide, index) => {
        return (
          <ImageGallerySliderItem key={'image-' + index} className={sliderElementClassName}>
            <CategoryImage
              onClick={onClick}
              normal={slide}
              sku={product.sku}
              title={product.title}
              alt={product.name}
              objectFit={'cover'}
              placeholder={'none'}
              loading={'eager'}
              className={categoryImageClassName || 'aspect-square w-full h-full'}
              productType={product.productType}
              sizes={sizes}
            />
          </ImageGallerySliderItem>
        )
      })}
    </Slider>
  )
}
ImageGallerySliderButtonPrev.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
ImageGallerySliderButtonNext.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
ImageGallerySliderItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  onClick: PropTypes.func,
  setImageClickDetails: PropTypes.func.isRequired,
  className: PropTypes.string,
}

CartItemImageSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
  product: PropTypes.object.isRequired,
  media_gallery: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  categoryImageClassName: PropTypes.string,
  sliderElementClassName: PropTypes.string,
}

export default CartItemImageSlider
