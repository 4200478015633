const { BackendError } = require('../../common/errors')


const getPaymentIntent = async () => {
  throw new BackendError('Payment method BitPay is not supported')
}
const paymentIntent = async () => {
  throw new BackendError('Payment method BitPay is not supported')
}

const surcharge = (total) => total * 0.03

module.exports = { paymentIntent, surcharge, getPaymentIntent }

