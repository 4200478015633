const { BackendError } = require('../../common/errors')
const axios = require('axios')

const getPaymentIntent = async (paymentInentId) => {
  try {
    // Obtain the PayPal access token
    const accessToken = await getPayPalAccessToken()
    // console.log('PayPal access token', accessToken)
    const url = `https://api.paypal.com/v1/payments/payment/${paymentInentId}`

    // Make a GET request to the PayPal API
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    })
    const paymentDetails = response.data
    // Access the status of the payment
    const status = paymentDetails.state // The status might be in the 'state' field
    // Return the status
    // console.log('Payment Intent Status:', paymentDetails)
    return status
  } catch (error) {
    console.error('Error retrieving PayPal payment status:', error.message)
    throw new BackendError('Error getting PayPal payment status: ' + error.message)
  }
  // const result = {
  //   status: 'processing',
  //   amount: paymentIntent.amount/100,
  //   amount_received: 0,
  // }
  // return result
}
// Function to create a PayPal payment intent using Axios for direct HTTPS requests
const paymentIntent = async (state) => {
  const { cart, extra } = state
  console.log(`Generate PayPal payment intent for ${cart.length} items`)
  const accessToken = await getPayPalAccessToken() // Function to get access token

  console.log('PayPal access token', accessToken)
  const create_payment_json = {
    intent: 'sale',
    payer: {
      payment_method: 'paypal',
    },
    redirect_urls: {
      return_url: process.env.SITE_URL + '/thank-you-shopping?p=paypal&s=done&uid=' + extra.order_uid + '&id=' + extra.order_id,
      cancel_url: process.env.SITE_URL +'/checkout/#payment',
    },
    transactions: [
      {
        item_list: {
          items: cart.map(item => ({
            name: item.name,
            sku: item.sku,
            price: item.price.toString(),
            currency: 'USD',
            quantity: item.qty,
          })),
        },
        amount: {
          currency: 'USD',
          total: state.extra.total.toString(),
          details: {
            subtotal: state.extra.subtotal.toString(),
            tax: state.extra.tax.toString(),
            shipping: state.extra.shipping.toString(),
            handling_fee: state.extra.handling_fee.toString()
          }
        },
        description: `Gray & Sons Online Order #${state.extra.order_id}`,
        invoice_number: `W#${state.extra.order_id}`,
        note_to_payer: 'Contact us for any questions on your order: info@grayandsons.com,  305 865-0999',
      },
    ],
  }

  try {
    console.log('Create PayPal payment', create_payment_json)
    const response = await axios.post(
      'https://api.paypal.com/v1/payments/payment',
      create_payment_json,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )

    const payment = response.data
    console.log('PayPal payment', payment)
    let approvalUrl = payment.links.find(link => link.rel === 'approval_url').href
    return {
      intent: payment.id,
      url: approvalUrl,
    }
  } catch (error) {
    throw new BackendError('Error creating PayPal payment: ' + error.message)
  }
}

// Function to get PayPal access token
async function getPayPalAccessToken() {
  try {
    const response = await axios.post(
      'https://api.paypal.com/v1/oauth2/token',
      'grant_type=client_credentials',
      {
        headers: {
          Accept: 'application/json',
          'Accept-Language': 'en_US',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: process.env.PAYPAL_CLIENT_ID,
          password: process.env.PAYPAL_CLIENT_SECRET,
        },
      }
    )
    return response.data.access_token
  } catch (error) {
    throw new BackendError('Error retrieving PayPal access token: ' + error.message)
  }
}

const surcharge = total => total * 0.03

module.exports = { paymentIntent, surcharge, getPaymentIntent }
