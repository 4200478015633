import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CHECKOUT_STATUS_FAILED,
  CHECKOUT_STATUS_LOADING,
  CHECKOUT_STATUS_SUCCESS,
} from '../../state/cart'
import InputField from '../form/InputField'
import { IconCoupon, IconLoading, IconCheckmark, IconAlert } from '../IconsCheckout'

const CouponCodeInput = ({ form, onChange, couponStatus, couponClassName }) => {
  const [focus, setFocus] = useState(false)
  return (
    <>
      <InputField
        className="w-3/4 relative "
        inputClassName={'indent-8 ' + couponClassName}
        name={'coupon_code'}
        label={'Enter Coupon Code'}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        form={form}
        onChange={onChange}
      />

      <div className="w-6 h-6 absolute top-[45%] left-2 overflow-hidden ">
        {[CHECKOUT_STATUS_FAILED, CHECKOUT_STATUS_SUCCESS, CHECKOUT_STATUS_LOADING].indexOf(
          couponStatus
        ) === -1 && (
          <IconCoupon
            className={
              (focus || form['coupon_code'] ? 'left-0' : '-left-8') +
              ' absolute  w-full h-full text-gray-400 duration-150 ease-out'
            }
          />
        )}
        {couponStatus === CHECKOUT_STATUS_LOADING && (
          <IconLoading
            className={
              focus || form['coupon_code']
                ? 'absolute w-full h-full text-gray-400 left-0 animate-spin '
                : 'hidden'
            }
          />
        )}
        {couponStatus === CHECKOUT_STATUS_SUCCESS && (
          <IconCheckmark
            className={
              focus || form['coupon_code']
                ? 'absolute w-full h-full text-green-600 stroke-green-600 stroke-1 duration-150 ease-out left-0 animate-pulse'
                : 'hidden'
            }
          />
        )}
        {couponStatus === CHECKOUT_STATUS_FAILED && (
          <IconAlert
            className={
              focus || form['coupon_code']
                ? 'animate-shake absolute w-full h-full text-amber-400 duration-150 ease-out left-0 '
                : 'hidden'
            }
          />
        )}
        {/* <IconLoading className="w-full h-full text-gray-400 animate-spin " /> */}
      </div>
    </>
  )
}
CouponCodeInput.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  couponStatus: PropTypes.string,
  couponClassName: PropTypes.string,
}

export default CouponCodeInput
