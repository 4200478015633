const bankwire = require('./bankwire')
const affirm = require('./affirm')
const bitpay = require('./bitpay')
const paypal = require('./paypal')
const stripe = require('./stripe')
const { BackendError } = require('../../common/errors')

const getPaymentMethod = (paymentMethod) => {
  switch ((paymentMethod || '').toLowerCase()) {
    case 'creditcard':
      return stripe
    case 'affirm':
      return affirm
    case 'paypal':
      return paypal
    case 'bankwire':
      return bankwire
    default:
      throw new BackendError(`Payment method ${paymentMethod} is not supported`)
  }
}

const getPaymentIntent = async (paymentMethod, paymentIntentId) => {
  console.log('getPaymentIntent', paymentMethod, paymentIntentId)
  return getPaymentMethod(paymentMethod).getPaymentIntent(paymentIntentId)
}

const createPaymentIntent = async (state) => {
  console.log('createPaymentIntent', state)
  const { payment_method: paymentMethod } = state.checkout
  return getPaymentMethod(paymentMethod).paymentIntent(state)
}

const surcharge = (paymentMethod, total) => {
  return getPaymentMethod(paymentMethod).surcharge(total)
}

module.exports = { bankwire, createPaymentIntent, surcharge, affirm, bitpay, paypal, creditcard: stripe, getPaymentIntent }
