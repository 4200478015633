const getPaymentIntent = async () => {
  const result = {
    status: 'processing',
  }
  return result
}

const paymentIntent = async () => {
  return {
    intent: 'bankwire',
    url: false
  }
}

const surcharge = () => 0

module.exports = { paymentIntent, surcharge, getPaymentIntent }
