import * as React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { USD_P2 } from '../../helpers/NumberHelper'
import { applyCoupon, CHECKOUT_STATUS_INITIAL, CHECKOUT_STATUS_SUCCESS, resetCoupon } from '../../state/cart'
import { updateCheckoutField } from '../../state/checkout'
import cartTotal, { surchargeAmount } from '../../helpers/cart/cartTotal'
import FormWrapper from '../form/FormWrapper'
import CouponCodeInput from './CouponCodeInput'
import CartItemCard from './CartItemCard'

const CartSummary = () => {
  const cart = useSelector(state => state.cart)
  const checkout = useSelector(state => state.checkout)
  const couponStatus = useSelector(state => state.cart.coupon_status)
  const cartProducts = useSelector(state => state.cart.products)
  const errors = useSelector(state => state.cart.errors)
  const surcharge = surchargeAmount(cart, checkout.payment_method)
  const [enterCouponCode, setEnterCouponCode] = useState(false)

  const clickToEnterCouponCode = () => {
    setEnterCouponCode(!enterCouponCode)
  }

  const dispatch = useDispatch()
  const applyCouponCode = React.useCallback(() => {
    dispatch(applyCoupon({ cart, checkout }))
  }, [cart, checkout])
  // Execute applyCouponCode when cart changes
  React.useEffect(() => {
    if(!checkout.coupon_code) return
    console.log('reapplying the coupon code')
    dispatch(applyCoupon({ cart, checkout }))
  }, [cartProducts, checkout.coupon_code])

  const updateCouponCode = React.useCallback(
    e => {
      dispatch(updateCheckoutField({ name: 'coupon_code', value: e.target.value }))
      dispatch(resetCoupon())
    },
    [updateCheckoutField]
  )
  const getError = sku => {
    return (errors || []).find(error => error?.sku === sku)
  }

  const [largeViewSku, setLargeViewSku] = useState('')
  React.useEffect(() => {
    if (cartProducts.length > 0) {
      setLargeViewSku(cartProducts[0].sku)
    }
  }, [cartProducts.length])

  const [toggleCart, setToggleCart] = useState(false)
  const expandClicked = () => {
    setToggleCart(!toggleCart)
    if (!toggleCart) {
      window.scrollTo(0, 80)
    }
  }

  return (
    <>
      <section
        id="CartSummary"
        className={
          'relative bg-white top-0 order-1 border mt-5 md:border-none md:pb-0 w-full md:w-auto sm:max-w-[580px] md:max-w-[25rem] p-4'
        }
      >
        <button
          onClick={expandClicked}
          className="absolute -bottom-4 left-[50%] w-44 -translate-x-[50%] text-red-700 text-sm uppercase md:hidden h-8 border bg-white shadow-md"
        >
          {toggleCart ? 'click to show less' : 'click for details'}
        </button>
        <button
          onClick={expandClicked}
          className="font-bold mx-auto underline-title uppercase text-lg "
        >
          Cart Summary
        </button>
        <div
          className={
            (toggleCart ? ' ' : ' hidden md:block ') +
            ' w-full md:max-w-[500px] cursor-default relative'
          }
        >
          {cartProducts.map(product => (
            <CartItemCard
              product={product}
              onClick={() => setLargeViewSku(product.sku)}
              key={product.sku}
              large={largeViewSku}
              error={getError(product.sku)}
            />
          ))}
        </div>
        <div
          className={
            (toggleCart ? 'border-t ' : ' ') +
            ' w-full flex flex-col md:cursor-default md:border-t '
          }
        >
          <div>
            <button
              onClick={clickToEnterCouponCode}
              className={
                (toggleCart ? ' flex  ' : ' hidden md:flex ') +
                (enterCouponCode ? 'text-black' : 'text-gray-500') +
                ' relative flex-row text-sm'
              }
            >
              Coupon Code
              <div className="w-[.8rem] h-1 relative translate-y-[.45rem] mx-[.3rem]">
                <p
                  className={
                    (enterCouponCode ? 'skew-y-[0deg]' : 'skew-y-[36deg] ') +
                    ' absolute top-0 left-0 w-[.4rem] h-[.15rem] bg-gray-400 duration-300 translate-y-[.15rem]'
                  }
                ></p>
                <p
                  className={
                    (enterCouponCode ? 'skew-y-[0deg] ' : 'skew-y-[-36deg]') +
                    ' absolute top-0 right-0 w-[.4rem] h-[.15rem] bg-gray-400 duration-300 translate-y-[.15rem]'
                  }
                ></p>
              </div>
            </button>
            {enterCouponCode && (
              <FormWrapper
                action={applyCouponCode}
                saveOnSubmit={false}
                name={'Coupon Code'}
                form={checkout}
                className={'flex flex-row items-end animate-fade-in'}
                minimumTimeout={0}
              >
                <CouponCodeInput
                  form={checkout}
                  onChange={updateCouponCode}
                  couponStatus={checkout.coupon_code ? couponStatus : CHECKOUT_STATUS_INITIAL}
                  couponClassName={
                    couponStatus === 'error'  
                      ? 'border-amber-400'
                      : couponStatus === 'success'
                        ? 'border-green-500'
                        : ''
                  }
                />

                <button className="text-center text-white bg-gray-400 hover:bg-red-700 w-1/4 h-12">
                  APPLY
                </button>
              </FormWrapper>
            )}
            <div
              className={
                (toggleCart ? ' grid ' : ' hidden md:grid ') + ' grid-cols-2 gap-y-2 py-2 relative'
              }
            >
              {couponStatus === 'error' && (
                <p className="text-red-700">
                  <div className="w-3 h-3 border-2 border-red-600 rounded-full bg-red-600 animate-pulse inline-block" />{' '}
                  Invalid Code! Please contact us.
                </p>
              )}
              {couponStatus === CHECKOUT_STATUS_SUCCESS && !cart.coupon_result.applied && (
                <p className="text-amber-600 col-span-2 text-sm italic">
                  {cart.coupon_result.description}
                </p>
              )}
              Subtotal: &ensp;
              {couponStatus === 'loading' ? (
                <div className="text-right inline-block skeleton h-4 py-2 bg-gray-200/80 rounded-md overflow-hidden max-w-max text-transparent relative translate-y-[.15rem]">
                  Calculating...
                </div>
              ) : (
                <p className="text-right text-gray-500">{USD_P2(cart.total)}</p>
              )}
              <div>
                Shipping:{' '}
                <a className="bg-gray-300 text-white group font-bold px-2 rounded-full hover:bg-gray-700 duration-150 relative">
                  ?
                  <p className="font-thin text-sm hidden group-hover:block group-active:block absolute top-[1.1rem] scrollbar-thin h-max w-[20vw] max-w-[250px] left-0 bg-gray-700 p-2 rounded-md justify-center items-center z-10">
                    The shipping cost is calculated based on your shipping Zip Code. All orders are
                    shipped "Next-Day" and insured.
                  </p>
                </a>
              </div>
              <div className="flex justify-end text-gray-500">
                {cart.status === 'loading' || couponStatus === 'loading' ? (
                  <div className="items-end justify-end inline-block skeleton h-4 py-2 bg-gray-200/80 rounded-md overflow-hidden max-w-max text-transparent relative translate-y-[.15rem]">
                    Calculating...
                  </div>
                ) : (
                  <>
                    <p className="text-right">
                      <span
                        className={
                          cart.coupon_result?.free_shipping
                            ? 'line-through text-gray-400 text-right'
                            : ''
                        }
                      >
                        {USD_P2(cart.shipping)}
                      </span>
                      {cart.coupon_result?.free_shipping ? (
                        <em className="block text-emerald-800"> Free Shipping</em>
                      ) : (
                        ''
                      )}
                    </p>
                  </>
                )}
              </div>
              {surcharge && surcharge > 0 ? (
                <div className="grid grid-cols-2 col-span-2">
                  <div>
                    Surcharge:{' '}
                    <a className="bg-gray-300 text-white group font-bold px-2 rounded-full hover:bg-gray-700 duration-150 relative">
                      ?
                      <p className="font-thin text-sm hidden group-hover:block group-active:block absolute top-[1.1rem] scrollbar-thin h-max w-[20vw] max-w-[250px] left-0 bg-gray-700 p-2 rounded-md justify-center items-center z-10">
                        One or more of the items in your cart have a "Bank Wire Only Price", by
                        selecting a different method of payment a processing fee is added.
                      </p>
                    </a>
                  </div>
                  <p className="text-right text-gray-500">{USD_P2(surcharge)}</p>
                </div>
              ) : null}
              <div>
                Sales Tax:{' '}
                <a className="bg-gray-300 text-white group font-bold px-2 rounded-full hover:bg-gray-700 duration-150 relative">
                  ?
                  <p className="font-thin text-sm hidden group-hover:block group-active:block absolute top-[1.1rem] scrollbar-thin h-max w-[20vw] max-w-[250px] left-0 bg-gray-700 p-2 rounded-md justify-center items-center z-10">
                    The tax amount varies based on your shipping city and state.
                  </p>
                </a>
              </div>
              <div className="flex justify-end text-gray-500">
                {cart.status === 'loading' || couponStatus === 'loading' ? (
                  <div className="inline-block skeleton w-full h-4 bg-gray-200/80 rounded-md overflow-hidden max-w-max text-transparent relative translate-y-[.15rem]">
                    Calculating...
                  </div>
                ) : (
                  <p>{USD_P2(cart.tax)}</p>
                )}
              </div>
              {cart.status !== 'loading' &&
              couponStatus !== 'loading' &&
              cart.coupon_result?.discount ? (
                <>
                  Coupon Discount: &ensp;
                  <p className="text-right">
                    {USD_P2(cart.coupon_result?.discount)}
                    {/* {cart.coupon_result?.description} */}
                  </p>
                </>
                ) : null}
            </div>
            <div
              className={
                (toggleCart
                  ? ' font-bold '
                  : ' flex text-xl justify-center md:text-base md:flex-col ') +
                ' py-2 grid grid-cols-2 '
              }
            >
              TOTAL: &ensp;
              <div className=" text-right ">
                {cart.status === 'loading' || couponStatus === 'loading' ? (
                  <div className="text-right inline-block text-xl skeleton w-full max-h-max bg-gray-200/80 rounded-md overflow-hidden max-w-max text-transparent relative ">
                    Calculating...
                  </div>
                ) : (
                  <p>{USD_P2(cartTotal(cart, checkout.payment_method))}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CartSummary
