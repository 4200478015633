import * as React from 'react'
import Seo from '../components/Seo'
import Icon, { IconShoppingCartDL } from '../components/Icons'
import IconsCheckout from '../components/IconsCheckout'
import CheckOutHeader from '../components/checkout/CheckOutHeader'
import CheckoutProcess from '../components/checkout/CheckoutProcess'
import NewArrivalSlider from '../components/sliders/NewArrivalSlider'
import { showStatusPopup, CHECKOUT_STATUS_FAILED } from '../state/cart'
import '../styles/main.scss'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const CheckoutPage = ({ location }) => {
  const cartProducts = useSelector(state => state.cart.products)
  const cartStatus = useSelector(state => state.cart.status)
  const cartStatusPopup = useSelector(state => state.cart.status_popup)
  const cartStatusMessage = useSelector(state => state.cart.status_message)
  const [state, setState] = React.useState('loading')

  const [step, setStep] = React.useState(1)

  const [showAlert, setShowAlert] = React.useState(false)
  const dispatch = useDispatch()
  const closeAlert = React.useCallback(() => {
    setShowAlert(false)
    dispatch(showStatusPopup({ show: false }))
  }, [])

  React.useEffect(() => {
    if (cartStatusPopup === true && cartStatus === CHECKOUT_STATUS_FAILED) {
      setShowAlert(true)

      const timer = setTimeout(() => {
        closeAlert()
      }, 5000)

      return () => {
        closeAlert()
        clearTimeout(timer)
      }
    }
  }, [cartStatus, cartStatusPopup])

  React.useEffect(() => {
    if (cartProducts?.length > 0) {
      setState('checkout')
    } else {
      setState('loading')
      const timer = setTimeout(() => {
        setState('empty')
      }, 2000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [cartProducts, setState])

  return (
    <div className="overflow-x-hidden flex flex-col min-h-screen">
      <CheckOutHeader step={step} />
      <IconsCheckout />
      <Icon />
      <Seo title="Checkout | Gray&Sons" noindex={true} />

      {state === 'checkout' ? (
        <CheckoutProcess step={step} location={location} setStep={setStep} />
      ) : (
        <div className="w-full flex items-center flex-col ">
          <div
            className={
              'w-[40vw] h-[40vw] max-w-[300px] max-h-[300px] mx-auto flex-grow-0 ' +
              (state === 'loading' ? 'animate-pulse ' : '')
            }
          >
            <IconShoppingCartDL className={'w-full text-gray-300'} />
          </div>
          {state === 'empty' && (
            <>
              <p className="py-10 text-xl text-center fade-in-now">
                This cart is currently empty.
                <br />
                <span className="text-base">
                  Please add some items to your cart before checking out.
                </span>
              </p>
              <hr className="w-10/12 max-w-[800px] mx-auto my-10" />
              <div className="w-10/12 mx-auto fade-in-now">
                <NewArrivalSlider />
              </div>
            </>
          )}
        </div>
      )}

      {showAlert === true && (
        <>
          <button
            onClick={closeAlert}
            className="cart-alert-motion cursor-pointer text-red-700 h-24 flex flex-row text-sm fixed left-[50%] -translate-x-[50%] bg-white border-2 border-red-600 z-30 shadow-md "
          >
            <div className="bg-red-600 aspect-square w-24 h-full absolute left-0 top-0 z-20 text-white font-black text-6xl flex items-center justify-center">
              !
            </div>
            <p className="pl-28 pr-4 py-2 m-auto alert-fade-text whitespace-pre-line block">
              {cartStatusMessage}
            </p>
          </button>
          <button
            className="fixed top-0 left-0 w-full h-full bg-black opacity-20 backdrop-blur-sm cursor-pointer z-20"
            onClick={closeAlert}
          />
        </>
      )}
    </div>
  )
}
CheckoutPage.propTypes = {
  location: PropTypes.object,
}

export default CheckoutPage
