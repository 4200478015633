import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import USD from '../../helpers/NumberHelper'
import CategoryImage from '../CategoryImage'
import { removeProduct, CHECKOUT_STATUS_INITIAL, changeStatus } from '../../state/cart'
import { IconCirclePlus } from '../Icons'
import IconInfo from '../../icons/IconInfo'
import CartItemImageSlider from '../sliders/CartItemImageSlider'
import useMediaQuery from '../../hooks/useMediaQuery'
import TailwindScreens from '../../helpers/tailwind'
import finalPrice from '../../helpers/product/finalPrice'

const CartItemCard = ({ product, onClick, large, error }) => {
  const isLg = useMediaQuery(TailwindScreens.lg)
  const dispatch = useDispatch()
  const [isDeleted, setIsDeleted] = useState(false)
  React.useEffect(() => {
    if (isDeleted) {
      const timeout = setTimeout(() => {
        dispatch(removeProduct({ sku: product.sku }))
        dispatch(changeStatus({ status: CHECKOUT_STATUS_INITIAL }))
      }, 300)
      return () => clearTimeout(timeout)
    }
  }, [dispatch, product.sku, isDeleted])
  const removeProductFromCart = React.useCallback(() => {
    setIsDeleted(true)
  }, [])
  const showLargeView = product.sku === large && !isDeleted
  const [showItemInfo, setShowItemInfo] = useState(false)

  const toggleItemInfo = () => {
    setShowItemInfo(!showItemInfo)
  }

  return (
    <div className={'relative'}>
      <div
        className={
          (error ? 'border-red-700 text-red-700 ' : 'border-gray-300 hover:border-gray-500 ') +
          (isDeleted ? 'opacity-0 max-h-0 -m-1 p-0 ' : 'opacity-100 max-h-[500px] my-2 ') +
          (showLargeView ? 'md:flex-col ' : ' ') +
          ' duration-300 flex flex-row w-full '
        }
      >
        <button
          onClick={onClick}
          className={
            (error ? 'saturate-0 blur-sm ' : '') +
            (showLargeView ? ' md:w-full ' : ' ') +
            'w-1/4 sm:w-1/3 aspect-square duration-300 relative mr-1 md:mr-0 '
          }
        >
          {showLargeView && isLg ? (
            <CartItemImageSlider
              className={'rounded-lg '}
              product={product}
              media_gallery={product.media_gallery}
              sliderElementClassName="aspect-square p-2"
            />
          ) : (
            <CategoryImage
              normal={product.image}
              sku={product.sku}
              title={product.title}
              alt={product.name}
              // url={product.url}
              objectFit={'cover'}
              placeholder={'none'}
              loading={'eager'}
              className="aspect-square w-full h-full"
              productType={product.productType}
              sizes="(min-width: 48em) 30em, 33vw"
            />
          )}
        </button>
        {showLargeView && (
          <div className="overflow-hidden hidden lg:block w-full h-full absolute">
            <button
              onClick={toggleItemInfo}
              className="group z-10 peer absolute top-0 left-0 w-8 h-8 rounded-full bg-white  duration-200 flex justify-center items-center text-gray-600 hover:text-white"
            >
              <IconInfo
                className={
                  'border-2 w-6 h-6 group-hover:bg-gray-600 border-gray-600 p-1 rounded-full'
                }
              />
            </button>
            <div
              className={
                ' absolute z-30 top-1 -right-[100%] peer-hover:right-0 bottom-4 scrollbar-thin overflow-auto hover:right-0 w-full bg-white duration-300 pr-5 pl-9 overflow-y-auto h-full '
              }
            >
              <p className="text-xl font-bold uppercase">{product.title}</p>
              <p dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
          </div>
        )}
        <div className=" w-full flex flex-wrap items-end justify-start">
          <div
            className={
              (showLargeView ? '  md:line-clamp-3 ' : ' ') +
              'font-bold uppercase text-sm line-clamp-1 sm:line-clamp-2'
            }
          >
            {product.name}
          </div>
          <div
            className={
              '@container flex flex-row justify-between py-2 w-full' +
              (product.special_price || 0 > 0 ? ' text-red-700' : '')
            }
          >
            <div className="flex justify-start w-full font-bold text-xl">
              {USD(finalPrice(product))}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={removeProductFromCart}
        className={
          (isDeleted ? 'hidden' : '') +
          ' text-left col-span-2 bottom-4 right-0 absolute lg:col-span-1 xl:col-span-1 text-sm text-gray-400 hover:text-red-700 duration-150'
        }
      >
        <nobr className="flex flex-row items-end">
          <IconCirclePlus className="w-5 h-5 rotate-45 stroke-1 stroke-white" />
          &ensp; <span className={showLargeView ? 'inline' : 'hidden'}>Remove</span>
        </nobr>
      </button>
    </div>
  )
}
CartItemCard.propTypes = {
  product: PropTypes.object,
  onClick: PropTypes.func,
  large: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
    }),
    PropTypes.string,
  ]),
}
export default CartItemCard
