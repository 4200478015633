import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { updateCheckoutField } from '../../state/checkout'
import useHashState from '../../hooks/useHashState'
import { USD_P2 } from '../../helpers/NumberHelper'
import { surchargeAmount } from '../../helpers/cart/cartTotal'
import { MAXIMUM_AFFIRM_AMOUNT, MINIMUM_AFFIRM_AMOUNT } from '../../api-common/payments/affirm'

const InputCheckbox = ({ checkout, name, value, onChange }) => {
  return (
    <input
      type="radio"
      className="radio-input"
      checked={checkout[name] === value}
      name={name}
      value={value}
      onChange={onChange}
    />
  )
}
InputCheckbox.propTypes = {
  checkout: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

const CheckoutPayment = ({ setStep }) => {
  const checkout = useSelector(state => state.checkout)
  const cart = useSelector(state => state.cart)
  const [allPayments, setAllPayments] = useHashState('all-payments', false)
  const [clickCnt, setClickCnt] = React.useState(0)
  const [pristine, setPristine] = React.useState(true)

  const dispatch = useDispatch()
  const selectedPayment = !!checkout.payment_method
  const selected = selectedPayment && !pristine 
  React.useEffect(() => {
    if (!selectedPayment || pristine) {
      return
    }
    let timeout = setTimeout(() => {
      setStep(3)
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [selectedPayment, pristine, setStep])

  React.useEffect(() => {
    let timeout = null
    if (clickCnt > 0) {
      timeout = setTimeout(() => {
        setClickCnt(0)
      }, 500)
    }
    if (clickCnt >= 4) {
      setAllPayments(true)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [clickCnt])

  const updateCheckout = React.useCallback(
    e => {
      setPristine(false)
      dispatch(updateCheckoutField({ name: e.target.name, value: e.target.value }))
    },
    []
  )

  const paymentMethods = (
    allPayments
      ? [
        { name: 'Credit Card', value: 'CreditCard' },
        { name: 'PayPal', value: 'PayPal' },
        { name: 'Wire Transfer', value: 'BankWire' },
        { name: 'Affirm', value: 'Affirm' },
        { name: 'BitPay', value: 'BitPay' },
      ]
      : [
        { name: 'Credit Card', value: 'CreditCard' },
        //{ name: 'PayPal', value: 'PayPal' },
        { name: 'Wire Transfer', value: 'BankWire' },
        MINIMUM_AFFIRM_AMOUNT <= cart.total && cart.total <= MAXIMUM_AFFIRM_AMOUNT
          ? { name: 'Affirm', value: 'Affirm' }
          : null,

        //{ name: 'BitPay', value: 'BitPay' },
      ]
  ).filter(Boolean)

  return (
    <form className="w-full flex flex-col gap-4">
      <section className="flex flex-col lg:flex-row gap-8 ">
        <div>
          <h2 className="pb-4 text-3xl font-bold uppercase text-gray-500">SHIPPING DETAILS</h2>
          <div>
            {checkout.shipping_name} {checkout.shipping_last_name}
            <br />
            {checkout.shipping_address}
            {checkout.shipping_address2}
            <br />
            {checkout.shipping_city}, {checkout.shipping_state} {checkout.shipping_zip}
            <br />
            {checkout.email} {checkout.phone}
          </div>
          <button
            onClick={e => {
              e.preventDefault()
              setStep(1)
            }}
            className="my-5 text-gray-500 hover:text-red-700"
          >
            Change
          </button>
        </div>
        <div className="lg:px-10 lg:border-l-2">
          <h2 className="pb-4 text-3xl font-bold uppercase text-gray-500">BILLING DETAILS</h2>
          <div>
            {checkout.billing_name} {checkout.billing_last_name}
            <br />
            {checkout.billing_address}
            {checkout.billing_address2}
            <br />
            {checkout.billing_city}, {checkout.billing_state} {checkout.billing_zip}
            <br />
            {checkout.billing_email} {checkout.billing_phone}
          </div>
          <button
            onClick={e => {
              e.preventDefault()
              setStep(1)
            }}
            className="my-5 text-gray-500 hover:text-red-700"
          >
            Change
          </button>
        </div>
      </section>

      <div className="lg:hidden border-t-2" />
      <section className="flex w-full flex-col justify-center">
        <h2 className=" text-3xl font-bold flex-row text-left uppercase text-gray-500 mb-2 inline-block md:flex items-center">
          <div className={selected ? 'chosen-payment' : ''}>CHOOSE&nbsp;</div>
          <button onClick={() => setClickCnt(c => c + 1)}>PAYMENT METHOD</button>
        </h2>
        <div className="flex flex-col group">
          {paymentMethods.filter(m => m).map(method => {
            const bnakwireOnlyItems =  cart.products.filter(item => item.ask_for_price === 2).length > 0
            const surcharge = surchargeAmount(cart, method.value)
            return(
              <label
              key={method.value}
              className={
                'radio-label w-80  overflow-hidden duration-700 ease-out ' +
                (selected && checkout.payment_method !== method.value
                  ? 'payment-selection'
                  : 'h-12 p-2')
              }
              >
                <InputCheckbox
                checkout={checkout}
                name="payment_method"
                value={method.value}
                onChange={updateCheckout}
                />
                <div className="radio-design" />
                <div className="radio-text">{method.name.toUpperCase()}
                  {surcharge > 0 ? <div className="text-gray-500 group-hover:text-red-700 text-xs leading-0 ">(surcharge {USD_P2(surcharge)})</div> : null}
                  {bnakwireOnlyItems && surcharge <= 0 && <div className="text-emerald-700 text-xs leading-0">(save 3% with wire)</div>}
                </div>
              </label>
            )})}
        </div>
      </section>

      <button
        className="col-span-2 w-full md:w-48 border-2 border-red-700 bg-white py-2 font-bold text-red-700 hover:border-red-700 hover:bg-red-700 hover:text-white lg:col-span-1 xl:col-span-1 duration-300 "
        onClick={e => {
          e.preventDefault()
          if (checkout.payment_method) {
            setStep(step => step + 1)
          } else {
            alert('Please select a payment method')
          }
        }}
      >
        NEXT
      </button>
    </form>
  )
}
CheckoutPayment.propTypes = {
  setStep: PropTypes.func,
}

export default CheckoutPayment
