const { BackendError } = require('../../common/errors')

const getPaymentIntent = async paymentInentId => {
  const Stripe = require('stripe')
  const stripe = new Stripe(process.env.STRIPE_SK, { apiVersion: '2023-10-16' })
  const paymentIntent = await stripe.paymentIntents.retrieve(paymentInentId)
  if(!paymentIntent) {
    throw new BackendError('Payment intent not found')
  }
  console.log('Payment intent:', JSON.stringify(paymentIntent, null, 2))
  const result = {
    status: 'processing',
    amount: paymentIntent.amount/100,
    amount_received: 0,
  }
  if (paymentIntent.status === 'succeeded') {
    result.status = 'paid'
    result.amount = paymentIntent.amount/100
    result.amount_received = paymentIntent.amount_received/100
  } else  {
    result.status = paymentIntent.status
  } 
  return result
}

const paymentIntent = async state => {
  try {
    const { cart, extra, checkout } = state
    const Stripe = require('stripe')
    const stripe = new Stripe(process.env.STRIPE_SK, { apiVersion: '2023-10-16' })
    const price =  state.extra.total
    const paymentIntent = await stripe.paymentIntents.create({
      amount: Math.round(price * 100),
      currency: 'usd',
      description:  `Order #${extra.order_id} by ${checkout.shipping_name} ${checkout.shipping_last_name}`,
    })
    return {
      url: paymentIntent.client_secret,
      intent: paymentIntent.id,
    }
  } catch (error) {
    console.error('Error creating Stripe payment intent:', error)
    throw new BackendError('Error creating Stripe payment intent: ' + error.message)
  }
}

const surcharge = total => total * 0.03

module.exports = { paymentIntent, surcharge, getPaymentIntent }
